import PeopleBase from "./PeopleBase";
import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios'
export default class Transportista extends PeopleBase
{
    transportistaId:number=0;
    razonSocial:string= "";


    static async Obtener():Promise<ResponseCustom<Transportista>>
    {
      return await Obtener<Transportista>("Transportista/Obtener",mainAxios);
    }

    static async ObtenerSelect():Promise<ResponseCustom<Transportista>>
    {
      return await Obtener<Transportista>("ConTransportistaratista/ObtenerSelect",mainAxios);
    }

    static async Guardar(transportista:Transportista):Promise<ResponseGenerico>
    {
        return await Save<Transportista>("Transportista/Guardar",transportista,mainAxios);
    }

    static async EditarEstado(transportista:Transportista):Promise<ResponseGenerico>
    {
        return await Editar<Transportista>("Transportista/EditarEstado",transportista,mainAxios);
    }

    static async Editar(transportista:Transportista):Promise<ResponseGenerico>
    {
        return await Editar<Transportista>("Transportista/Editar",transportista,mainAxios);
    }
}