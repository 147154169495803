
























































import {Vue,Component,Prop} from 'vue-property-decorator'
import {Placa,ObtenerPlaca} from '@/entidades/Plantacion/Placa';
@Component
export default class FormularioPlacas extends Vue
{
     header:{text:string,value:string}[] =[
            {text:"# Placa",value:'descripcion'},
            {text:"Eliminar",value:'delete'},
     ]

     listado:Placa[]= [];
     @Prop({required:true,type:Number})
     transportistaId!:number;

     agregar()
     {
         this.listado.push(new Placa(0,"",this.transportistaId,true));
     }

     eliminar(placa:Placa)
     {
         this.listado.splice(this.listado.indexOf(placa),1);
     }
     guardar()
     {
         this.$emit('guardar',this.listado);
     }
}
