






































































import Vue from 'vue'
import {Component} from 'vue-property-decorator';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Formulario from '@/components/Maestro/Transportista/Formulario.vue';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import Transportista from '@/entidades/Plantacion/Transportista';
import FormularioAsignaPlacas from '@/components/Maestro/Transportista/FormularioAsignaPlacas.vue';
import { Placa, GuardaListadoPlaca } from '@/entidades/Plantacion/Placa';


@Component({
    components:{
        TablaGeneral,
        Formulario,
        FormularioAsignaPlacas
    }
})
export default class TransportistaView extends  Vue
{

    //Propiedas y atributos
    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Ruc",sortable:true,value:'documento'},
            {text:"Razon Social",sortable:true,value:'razonSocial'},
            {text:"Vendor Sap",sortable:true,value:'vendorSap'},
            {text:"Email",sortable:true,value:'email'},
            {text:"Placa Asociada",sortable:true,value:'cantidad'},
            {text:"Agregar Placas",sortable:true, value:'campo'},
            {text:"Editar",sortable:false,value:'actions'},
            {text:"Estado",sortable:true,value:'estado'},
    ]
   listado:Transportista[]=[];
   search:string ="";
   titulo:string = "";
   action:number = 1;
   modalAgregarContratista:boolean =false;
   modalAgregarContratistaName:string = "";
   modalPlacaName:string ="";
   transportistaModelo: Transportista  = new Transportista();
   transportistaId:number=0;
   asignacionPlacas(item:Transportista)
   {
       console.log(item);
       this.transportistaId  = Number(item.transportistaId);
       this.modalPlacaName = "FormularioAsignaPlacas";
       this.modalAgregarContratista = true;
   }
   cerrarModalPlaca()
   {
       this.modalAgregarContratista =false;
       this.modalPlacaName = "";
       this.Obtener();
   }

   async guardarPlaca(placas:Placa[])
   {
       try
       {
           let response = await GuardaListadoPlaca(placas);
           if(response.isSuccess == true)
                this.cerrarModalPlaca();

            this.$store.commit('alert',{
                text : response.isMessage,
                position : 'center',
                color : 'info',
                timeout : 1000
            });
            
                
       }
       catch(error)
       {
           //@ts-ignore
           this.$store.commit('alert',{
               position: 'center',
               color : 'red',
               //@ts-ignore
               text : error.message,
               timeout : 1000
           })
       }
   }
    abrirModalAgregar()
    {
        this.action = 1 ;
        this.titulo = "Registro de tranpostista";
        this.transportistaModelo = new Transportista();
        this.modalAgregarContratistaName = "Formulario";
        this.modalAgregarContratista = true;
    }
   modalEditar(transportista:Transportista)
   {
       this.transportistaModelo  = transportista;
        this.action = 2;
        this.titulo = "Editar Transportista";
        this.modalAgregarContratistaName = "Formulario";
        this.modalAgregarContratista = true;
   }
   cerrarModal()
   {
        this.action = 1 ;
        this.titulo = "Registro de transportista";
        this.transportistaModelo = new Transportista();
        this.modalAgregarContratistaName = "";
        this.modalAgregarContratista = false;
   }

   async guardarDb()
   {
       try
       {
            let response = new ResponseGenerico(false,"");
            if(this.transportistaModelo != undefined)
                response = this.action == 1 ? await Transportista.Guardar(this.transportistaModelo) : await Transportista.Editar(this.transportistaModelo);


            if(response.isSuccess == true)
            {
                await this.Obtener();
                this.cerrarModal();
            } 

            let color:string = response.isSuccess ? 'info' : 'error';
            this.$store.commit('alert',{
               position : 'center',
               text : response.isMessage,
               color : color
           })

       }
       catch(error)
       {
           this.$store.commit('alert',{
               position : 'center',
               //@ts-ignore
               text : String(error.message),
               color : 'red'
           })
       }
   }

   async editarEstado(transportista:Transportista)
   {
        try
        {
            let response = await Transportista.EditarEstado(transportista);
            
            if(response.isSuccess == true) await this.Obtener();

            let color : string = response.isSuccess == true ? 'info' : 'red';
            this.$store.commit('alert',{
                position : 'center',
                text : response.isMessage,
                color : color,
                timeout: 1000
            })
        }
        catch(error)
        {   
             this.$store.commit('alert',{
                position : 'center',
                //@ts-ignore
                text : error.message,
                color : 'red',
                timeout: 1000
            })
        }
   }

    //Metodos async
    async Obtener()
    {
        try
        {
             let response = await Transportista.Obtener();
             if(response.isSuccess == true)
             {
                 this.listado = response.isResult;

             }
             else
             {
                 this.$store.commit('alert',{
                     position : 'center',
                     text : response.isMessage,
                     timeout : 1000,
                     color : 'red'
                 });
             }
        }
        catch(error)
        {
             this.$store.commit('alert',{
                     position : 'center',
                     //@ts-ignore
                     text : error.message,
                     timeout : 1000,
                     color : 'red'
                 });
        }
    }

   mounted() 
   {
       this.Obtener();
   }
}
