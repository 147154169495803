







































































import Transportista from '@/entidades/Plantacion/Transportista';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue,email} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';

@Component
export default class FormularioApp extends Vue 
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Prop({type:Number,required:true})
    action!:number;

    @Prop({type:String,required:true})
    titulo!:string;


    @Prop({required:true})
    modelo!:Transportista;


     @Validations()
     validations = {
         documento : {required},
         vendorSap : {required},
         razonSocial : {required},
         email : {required,email}

     }
     documento:string="";
     vendorSap:string ="";
     razonSocial:string ="";
     email:string = "";
     transportistaId:number=0

     //Metodos
     guardarInfo()
    {
        this.modelo.transportistaId = this.transportistaId;
        this.modelo.razonSocial = this.razonSocial;
        this.modelo.email = this.email;
        this.modelo.documento = this.documento;
        this.modelo.vendorSap = this.vendorSap;
        this.$emit('guardar');
    }

     //Propiedas computadas

       //Computed
    get texto()
    {
        if(this.action == 1)
        {
            return "GUARDAR";
        }
        else
        {
            this.documento = this.modelo.documento;
            this.razonSocial = this.modelo.razonSocial;
            this.email = this.modelo.email;
            this.transportistaId = this.modelo.transportistaId;
            this.vendorSap = this.modelo.vendorSap;
            return "EDITAR";
        }
    }
}
